import React, { useState } from "react";
import { Layout, Menu, message } from "antd";
import "antd/dist/reset.css";
import { Typography } from "antd";
import "./index.css";
import { Carousel, Card } from "antd";
import { Form, Input, Select, Button } from "antd";

const { Option } = Select;

const { Title } = Typography;

const { Header } = Layout;

const App = () => {
  return (
    <div className="App">
       <ImageSlider2 />
      <div className="acrylic-sports-container">
        
        <div className="acrylic-sports-title">
         Acrylic Sports & Things
        </div>
      </div>
      <div>
        <ImageSlider />
      </div>
      <div>
        <ItemForm />
      </div>
      <div>
        <ImageSlider3 />
      </div>
    </div>
  );
};
const ImageSlider = () => {
  return (
    <Carousel effect="fade" autoplay>
      <div>
      
        <div className="image-stack">
        <Card
            className="stacked-card"
            cover={
              <img src={process.env.PUBLIC_URL + "/Picture 4.png"} alt="Image 4" />
            }
          ></Card>
          <Card
            className="stacked-card"
            cover={
              <img src={process.env.PUBLIC_URL + "/Picture 5.png"} alt="Image 2" />
            }
          ></Card>

          <Card
            className="stacked-card"
            cover={
              <img src={process.env.PUBLIC_URL + "/Picture 6.png"} alt="Image 3" />
            }
          ></Card>

          <Card
            className="stacked-card"
            cover={
              <img src={process.env.PUBLIC_URL + "/Picture 7.png"} alt="Image 4" />
            }
          ></Card>
          
        </div>
      </div>
    </Carousel>
  );
};
const ImageSlider2 = () => {
  return (
    <Carousel effect="fade" autoplay>
      <div>
        <div className="image-stack">
          <Card
            className="stacked-card"
            cover={
              <img src={process.env.PUBLIC_URL + "/Picture 1.png"} alt="Image 2" />
            }
          ></Card>

          <Card
            className="stacked-card"
            cover={
              <img src={process.env.PUBLIC_URL + "/Picture 2.png"} alt="Image 3" />
            }
          ></Card>

          <Card
            className="stacked-card"
            cover={
              <img src={process.env.PUBLIC_URL + "/Picture 3.png"} alt="Image 4" />
            }
          ></Card>
         
        
       
        </div>
      </div>
    </Carousel>
  );
};
const ImageSlider3 = () => {
  return (
    <Carousel effect="fade" autoplay>
      <div>
      
        <div className="image-stack">
          <Card
            className="stacked-card"
            cover={
              <img src={process.env.PUBLIC_URL + "/Picture 13.png"} alt="Image 2" />
            }
          ></Card>

<Card
            className="stacked-card"
            cover={
              <img src={process.env.PUBLIC_URL + "/Picture 8.png"} alt="Image 4" />
            }
          ></Card>
         
         <Card
            className="stacked-card"
            cover={
              <img src={process.env.PUBLIC_URL + "/Picture 16.png"} alt="Image 4" />
            }
          ></Card>
          <Card
            className="stacked-card"
            cover={
              <img src={process.env.PUBLIC_URL + "/Picture 15.png"} alt="Image 4" />
            }
          ></Card>
        
       
        </div>
      </div>
    </Carousel>
  );
};
const ItemForm = () => {
  const [quantity, setQuantity] = useState(1);

  const handleQuantityIncrease = () => {
    setQuantity(quantity + 1);
  };

  const handleQuantityDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const onFinish = (values) => {
    values["qt"] = quantity;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(values);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://activatewm.com/sports/email/send", requestOptions)
      .then((response) => response.text())
      .then((result) => message.success("Request Submitted"))
      .catch((error) => message.error("Please try again"));
    // Perform form submission logic here
  };

  return (
    <div className="form-div">
  <Card
            className="stacked-card"
            cover={
              <img src={process.env.PUBLIC_URL + "/Picture 10.png"} alt="Image 4" />
            }
          ></Card>
  <Card className="form">
      
      <Form onFinish={onFinish} className="form-container">
        <h2 className="form-title">I am interested in</h2>

        <Form.Item label="interest" name="interest" className="input-container">
          <Select placeholder="Select an item">
            <Option value="Wayne Gretzky Rookie Card">Wayne Gretzky Rookie Card</Option>
            <Option value="Wayne Gretzky Silouette Card">Wayne Gretzky Silouette Card</Option>
            <Option value="Mario Lemieux Rookie Card">Mario Lemieux Rookie Card</Option>
            <Option value="Michael Jordan Rookie Card" >Michael Jordan Rookie Card</Option>
            <Option value="Michael Jordan Cradle Dunk Card">Michael Jordan Cradle Dunk Card</Option>
            <Option value="Oilers Connor McDavid Card">Oilers Connor McDavid Card</Option>
            <Option value="Sens Brady Tkachuck Card">Sens Brady Tkachuck Card</Option>
            <Option value="Sens Tim Stutzle Card">Sens Tim Stutzle Card</Option>
            <Option value="Sens Daniel Alfredsson Card">Sens Daniel Alfredsson Card</Option>
            <Option value="Ottawa Senators Logo Card">Ottawa Senators Logo Card</Option>
            <Option value="Back to The Future Movie Card">Back to The Future Movie Card</Option>
            <Option value="Back to The Future II Movie Card">Back to The Future II Movie Card</Option>
            <Option value="Back to The Future III Movie Card">Back to The Future III Movie Card</Option>
            <Option value="ET Movie Card">ET Movie Card</Option>
            <Option value="Tombstone Movie Card">Tombstone Movie Card</Option>
            <Option value="Batman 1966 TV Series Card">Batman 1966 TV Series Card</Option>
            <Option value="Batman Logo 1966 TV Series Card"> Batman Logo 1966 TV Series Card</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Quantity" className="quantity-container">
          <div className="quantity-buttons">
            <Button
              type="primary"
              onClick={handleQuantityDecrease}
              className="quantity-button"
            >
              -
            </Button>
            <span className="quantity-value">{quantity}</span>
            <Button
              type="primary"
              onClick={handleQuantityIncrease}
              className="quantity-button"
            >
              +
            </Button>
          </div>
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please enter your name" }]}
          className="input-container"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please enter your email" },
            { type: "email", message: "Invalid email" },
          ]}
          className="input-container"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Phone"
          name="phone"
          rules={[
            { required: true, message: "Please enter your phone number" },
          ]}
          className="input-container"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Address"
          name="address"
          rules={[{ required: true, message: "Please enter your address" }]}
          className="textarea-container"
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item className="submit-button">
          <Button type="primary" htmlType="submit">
            Submit Request
          </Button>
        </Form.Item>
      </Form>
    </Card>
    <Card
            className="stacked-card"
            cover={
              <img src={process.env.PUBLIC_URL + "/Picture 11.png"} alt="Image 4" />
            }
          ></Card>
    </div>
  
  );
};

export default App;
